<template>
    <div class="good-type-dictionary-page table-page__thead--sticky">
        <card body-class="overflow-visible" class="border-radius overflow-hidden" title="Good Type Dictionary" title-no-border>
            <template name="tools">
                <div slot="tools" class="relative flex items tools">
                    <button :disabled="!hasChanges" @click="save" class="btn-primary ml-4">Save</button>
                </div>
            </template>

            <div class="flex flex-col w-full">
                <div class="flex flex-wrap w-full">
                    <template v-for="(filter, fieldName) in filterModel">
                        <modal-field
                            v-if="filter.type === 'checkbox'"
                            :key="fieldName"
                        >
                            <checkbox v-model="filter.value" class="mt-2">
                                <span>Unfilled only</span>
                            </checkbox>
                        </modal-field>
                    </template>
                </div>
            </div>
        </card>

        <card  body-class="p-0 pl-0 pr-0 overflow-visible" class="border-radius bg-transparent">
            <v-table
                static
                checkable
                sortKey="id"
                class="admin-table border-radius overflow-hidden"
                query-params
                :sortOrder="-1"
                :text-wrappable="false"
                :exportable="false"
                :editable="true"
                :branding="false"
                :ref="tableRef"
                :key="tableRef"
                :columns="columns"
                :endpoint="endpoint"
                :filter-params="filterParams"
                :required-fields="['name']"
                :page-size="0"
                :on-rows-preloaded="showSavePopup"
            >
                <template slot="cell" slot-scope="{row, col, index}">
                    <div :class="getCellClasses(col.key)">
                        <template v-if="col.editable">
                            <text-input
                                :model="row"
                                :column="col"
                                :error="getServerError(row.id, col.key)"
                                @changed="updateRowModels"
                            />
                        </template>
                        <template v-else>
                            {{ row[col.key] || '-' }}
                        </template>
                    </div>
                </template>

                <div slot="bottom-tools" class="p-5 text-right">
                    <button :disabled="!hasChanges" @click="save" class="btn-primary ml-4">Save</button>
                </div>
            </v-table>
        </card>
    </div>
</template>

<script>
import axios from 'axios';
import TextInput from './TextInput';

export default {
    name: 'GoodTypeDictionaryIndex',
    components: {
        TextInput,
    },
    data() {
        return {
            filterModel: {
                code: {
                    type: 'multiselect',
                    value: [],
                },
                name: {
                    type: 'multiselect',
                    value: [],
                },
                carrier_name: {
                    type: 'multiselect',
                    value: [],
                },
                unfilled_only: {
                    type: 'checkbox',
                    value: false,
                },
                id: {
                    type: 'input',
                    value: null
                }
            },
            options: {},
            changedRows: [],
            serviceValidationErrors: {},
        }
    },
    methods: {
        getOptionsLabelByKey(key) {
            for (let i = 0; i < this.columns.length; ++i) {
                if (this.columns[i].key === key) {
                    return this.columns[i].title;
                }
            }
            return 'undefined';
        },
        getOptionsOfRow(optionsKey) {
            return this.options[optionsKey];
        },
        getCellClasses(colKey) {
            return 'pr-5';
        },
        updateFilterModel({updatedField, model}) {
            this.filterModel[updatedField].value = model;
        },
        updateRowModels({updatedField, model, value}) {
            let newData = {
                id: model.id,
                name: model.name,
            };

            newData[updatedField] = value;

            let hasChangedRows = false;
            for (let i = 0; i < this.changedRows.length; ++i) {
                if (this.changedRows[i].id === model.id) {
                    this.changedRows[i] = newData;
                    hasChangedRows = true;
                    break;
                }
            }

            if (false === hasChangedRows || this.changedRows.length === 0) {
                this.changedRows.push(newData);
            }
        },
        showSavePopup() {
            if (this.hasChanges && confirm('You are leaving the page. Do you want to save changes?')) {
                this.save();
            }
        },
        save() {
            if (false === this.hasChanges) {
                return;
            }
            axios.post(this.endpoint, this.changedRows).then(() => {
                this.resetChangedRows();
                this.resetValidationErrors();
                this.$snotify.success('Successfully updated!');
            }).catch((error) => {
                this.serviceValidationErrors = error.response.data.errors;
            });
        },
        getServerError(id, fieldName) {
            for (let i = 0; i < this.changedRows.length; ++i) {
                if (this.changedRows[i].id === id && this.serviceValidationErrors[`${i}.${fieldName}`]) {
                    return this.serviceValidationErrors[`${i}.${fieldName}`];
                }
            }
            return '';
        },
        resetChangedRows() {
            this.changedRows = [];
        },
        resetValidationErrors() {
            this.serviceValidationErrors = {};
        },
    },
    computed: {
        check () {
            return this.$refs[this.tableRef].$props
        },
        hasOptions() {
            return false === this.isEmptyObject(this.options);
        },
        hasChanges() {
            return this.changedRows.length > 0;
        },
        tableRef() {
            return 'good-type-dictionary';
        },
        endpoint() {
            return this.$apiUrl.goodTypeDictionary.base
        },
        filterParams() {
            let filterParams = {};

            for (let filterFieldName in this.filterModel) {
                const filter = this.filterModel[filterFieldName];

                if (filter.type === 'multiselect') {
                    if (filter.value.length > 0) {
                        filterParams[`filter[${filterFieldName}]`] = filter.value.map(option => option.value);
                    }
                } else if (filter.type === 'checkbox') {
                    if (filter.value) {
                        filterParams[`filter[${filterFieldName}]`] = filter.value;
                    }
                } else if (filter.value) {
                    filterParams[`filter[${filterFieldName}]`] = filter.value;
                }
            }

            return filterParams;
        },
        columns() {
            return [
                {
                    title: 'ID',
                    key: 'id',
                    editable: false,
                    toggled: true,
                    sortable: true,
                    truncate: true,
                    filterable: false,
                    width: 'w-8',
                },
                {
                    title: 'Code',
                    key: 'code',
                    editable: false,
                    toggled: true,
                    sortable: true,
                    truncate: false,
                    filterable: true,
                    width: 'w-8'
                },
                {
                    title: 'Name',
                    key: 'name',
                    editable: true,
                    toggled: true,
                    sortable: true,
                    truncate: false,
                    filterable: false,
                    width: 'w-8'
                },
                {
                    title: 'Carrier',
                    key: 'carrier_name',
                    editable: false,
                    toggled: true,
                    sortable: true,
                    truncate: false,
                    filterable: true,
                    width: 'w-36'
                },
            ];
        }
    },

    created() {
        if (this.$route.query.id) {
            this.filterModel.id.value = this.$route.query.id || null;
        }
    }
};
</script>

<style lang="scss">
.good-type-dictionary-page {
    .table-wrapper {
        max-height: calc(100vh - 360px);
        overflow: auto;
    }
}
</style>